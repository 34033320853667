import { useQuery } from '@apollo/client'
import { Box, Container, Flex, Text, Icon, SimpleGrid, VStack, Divider } from '@chakra-ui/react'
import React, { useState, useContext } from 'react'
import { FaRegClock } from 'react-icons/fa'
import { FaRegCalendarDays } from 'react-icons/fa6'
import { GET_USER_RSVPS } from 'src/utils/queries'
import { AuthContext } from 'src/utils/auth'
import Loading from '../Loading'
import RSVPedModal from './Modal'
import { formatDate, formatTime } from 'src/utils/globalFunctions'
import { RsvpProps } from 'src/utils/types'

export default function MySessions() {
  const { user } = useContext(AuthContext)
  const [userRsvp, setUserRsvp] = useState<RsvpProps[]>([])
  const [selectedRsvp, setSelectedSession] = useState<RsvpProps>({
    zoomLink: '',
    time: '',
    rsvpRecordId: '',
    date: '',
    course: { id: '', name: '', imageUrl: '', shortDescription: '', courseRecordId: '' },
  })
  const [isOpen, setIsOpen] = useState(false)
  const toggleShowModal = (session: RsvpProps) => {
    setSelectedSession(session)
    setIsOpen(true)
  }

  const { loading } = useQuery(GET_USER_RSVPS, {
    variables: { email: user?.email },
    onCompleted: (data: any) => {
      setUserRsvp(data.userRsvpData.rsvps)
    },
    onError: (error: any) => {
      console.error(error)
    },
  })
  return (
    <Container maxW="7xl" flexGrow={1} position="relative" textColor="brand.text" py={4}>
      <Box pb={2}>
        <Text
          mb={'20px'}
          lineHeight={'29.05px'}
          fontWeight={'700'}
          fontSize={'xl'}
          fontFamily={'Inter'}
        >
          My Sessions
        </Text>
        <SimpleGrid
          columns={{ base: 1, md: 2, lg: 3 }}
          spacingX={{ md: '25px', lg: '25px' }}
          spacingY={'50px'}
        >
          <RSVPedModal rsvp={selectedRsvp} setIsOpen={setIsOpen} isOpen={isOpen} />
          {loading ? (
            <Loading />
          ) : !userRsvp.length ? (
            <Text fontFamily={'Inter'} fontWeight={'500'} fontSize={'xl'} mt={'30px'}>
              No sessions to display yet.
            </Text>
          ) : (
            userRsvp?.map((rsvp, i) => (
              <Container key={i}>
                <Box
                  _hover={{ cursor: 'pointer', backgroundColor: '#4e658410' }}
                  onClick={() => toggleShowModal(rsvp)}
                  flexDirection={'column'}
                  boxShadow={'0px 1px 3px rgba(166, 175, 195, 0.4)'}
                  borderRadius={'8px'}
                  p={'35px'}
                  maxW={{ md: '350px', lg: '407px' }}
                  minH={'200px'}
                >
                  <Text fontFamily={'Inter'} fontWeight={'600'} fontSize={'xl'} mb={'30px'}>
                    {rsvp.course?.name}
                  </Text>
                  <Flex direction={'row'} justifyContent={'space-between'}>
                    <Flex>
                      <Icon
                        as={FaRegClock}
                        background={'#2F80ED1A'}
                        borderRadius={'8px'}
                        color={'brand.navy'}
                        width={'43px'}
                        height={'43px'}
                        p={'11px'}
                        mr={'10px'}
                      />
                      <VStack spacing={0} align={'flex-start'}>
                        <Text fontFamily={'Inter'} fontWeight={'bold'} fontSize={'14px'} mb={'3px'}>
                          Time
                        </Text>
                        <Text fontFamily={'Inter'} fontWeight={'500'} fontSize={'12px'}>
                          {formatTime(rsvp.time)}
                        </Text>
                      </VStack>
                    </Flex>
                    <Flex>
                      <Icon
                        as={FaRegCalendarDays}
                        background={'#2F80ED1A'}
                        borderRadius={'8px'}
                        color={'brand.navy'}
                        width={'43px'}
                        height={'43px'}
                        p={'11px'}
                        mr={'10px'}
                      />
                      <VStack spacing={0} align={'flex-start'}>
                        <Text fontFamily={'Inter'} fontWeight={'bold'} fontSize={'14px'} mb={'3px'}>
                          Date
                        </Text>
                        <Text fontFamily={'Inter'} fontWeight={'500'} fontSize={'12px'}>
                          {formatDate(rsvp?.date, 'year')}
                        </Text>
                      </VStack>
                    </Flex>
                  </Flex>
                </Box>
              </Container>
            ))
          )}
        </SimpleGrid>
      </Box>
      <Divider
        backgroundColor={'#DFE4EA'}
        orientation="horizontal"
        variant={'solid'}
        m={'auto'}
        mt={'50px'}
      />
    </Container>
  )
}
