import React, { useState, useContext } from 'react'
import {
  Image,
  Box,
  Text,
  Flex,
  Center,
  Spacer,
  Button,
  Hide,
  Stack,
  VStack,
} from '@chakra-ui/react'
import { useQuery, useMutation, useLazyQuery } from '@apollo/client'
import scheduler from 'src/assets/images/scheduler.png'
import { Loading } from 'src/components'
import { formatDate, formatTime } from 'src/utils/globalFunctions'
import { GET_SESSIONS, GET_USER_PROFILE_INFO } from 'src/utils/queries'
import { WelcomeContext } from 'src/utils/providers/useWelcome'
import { RSVP_FOR_SESSION, UPDATE_USER } from 'src/utils/mutations'
import { useNavigate } from 'react-router-dom'
import { SessionProps } from 'src/utils/types'
export default function RSVP() {
  const { setRSVPStatus, setError } = useContext(WelcomeContext)
  const [sessions, setSessions] = useState<SessionProps[]>()
  const [sessionName, setSessionName] = useState('')
  const navigate = useNavigate()

  const [getSessions, { loading }] = useLazyQuery(GET_SESSIONS, {
    onCompleted: (data: any) => {
      setSessions(data.sessionData.sessions)
      setSessionName(data.sessionData.sessions[0].session.split('-')[0].toString())
    },
    onError: (error: any) => {
      console.error(error)
    },
  })

  const { data } = useQuery(GET_USER_PROFILE_INFO, {
    onCompleted: (data: any) => {
      getSessions({
        variables: { courseId: data?.me?.path?.courses[0], email: data?.me?.email },
      })
    },
    onError: (error) => {
      console.error(error)
    },
  })

  const [rsvpForSession] = useMutation(RSVP_FOR_SESSION, {
    onCompleted: (data) => {
      setRSVPStatus(true)
      navigate('/dashboard')
    },
    onError: (error) => {
      console.error(error)
      setError(error.message)
    },
  })

  const [updateUser] = useMutation(UPDATE_USER, {
    refetchQueries: [{ query: GET_USER_PROFILE_INFO }],
    onError: (error) => {
      console.error(error)
    },
  })

  const submitRsvp = (email: string, session_id: string) => {
    updateUser({
      variables: {
        email: data.me.email,
        hasBeenWelcomed: true,
      },
    })
    rsvpForSession({
      variables: {
        userEmail: email,
        sessionId: session_id,
        isDebrief: true,
      },
    })
  }

  return (
    <Stack
      direction={{
        base: 'column',
        sm: 'row',
      }}
      w="full"
      h="100dvh"
    >
      <Hide below="md">
        <Box
          display="flex"
          alignItems="center"
          justifyContent={{
            base: 'center',
            sm: 'start',
          }}
          flexShrink={1}
        >
          <Image
            src={scheduler}
            objectFit={{
              base: 'cover',
              md: 'contain',
            }}
            h={'full'}
            loading="lazy"
          />
        </Box>
      </Hide>

      <Box pl={'5%'} pr={'5%'} maxW={'975px'}>
        <VStack
          gap={2}
          h="full"
          alignItems="start"
          justifyContent="center"
          pl={{
            base: 10,
            sm: 0,
          }}
          flexGrow={1}
          style={{
            marginInlineStart: 0,
          }}
        >
          <Text
            textTransform={'none'}
            fontFamily={'sans-serif'}
            color="white"
            fontSize={'4xl'}
            fontWeight="700"
          >
            Now grab a spot in your first Discovery session
          </Text>
          <span>
            <Text color="lightgray" mb={'20px'}>
              You completed your Seekr Survey. But what should you do next?
            </Text>
          </span>
          <span>
            <Text color="lightgray">
              Let's be honest, the job search can be overwhelming without the right support! Guided
              by a live coach, this 60-min group Zoom call will help you figure out your next step
              based on your goals and introduce you to a community of fellow first-gen job
              searchers.
            </Text>{' '}
          </span>

          <Box w={'100%'} h={'330px'} overflow={'scroll'}>
            <Text fontSize={'lg'} fontWeight={'bold'}>
              Select a date:
            </Text>
            {loading ? (
              <Loading />
            ) : (
              sessions?.map((session, index) => (
                <Flex
                  key={index}
                  mt={'20px'}
                  justifyContent={''}
                  p={'15px'}
                  h={'80px'}
                  borderRadius={'8px'}
                  background={'#343A47'}
                >
                  <Center w={'200px'} alignItems={'start'} flexDirection={'column'}>
                    <Text color={'lightgray'}>Date:</Text>
                    <Text fontWeight={'bold'}>{formatDate(session?.date, 'ordinal')}</Text>
                  </Center>
                  <Spacer />
                  <Center alignItems={'start'} flexDirection={'column'}>
                    <Text color={'lightgray'}>Time:</Text>
                    <Text fontWeight={'bold'}>{formatTime(session?.date)}</Text>
                  </Center>
                  <Spacer />
                  <Center>
                    <Button
                      variant="solid"
                      w={'111px'}
                      bg={'brand.blue'}
                      color={'white'}
                      title={'RSVP'}
                      ml={'20px'}
                      onClick={() => submitRsvp(data.me.email, session?.sessionId)}
                    >
                      RSVP
                    </Button>
                  </Center>
                </Flex>
              ))
            )}
          </Box>
        </VStack>
      </Box>
    </Stack>
  )
}
