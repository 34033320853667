import React, { useContext } from 'react'
import BracketModal from 'src/components/BracketGame/Modal'
import Instructions from 'src/components/BracketGame/Instructions'
import { Header } from 'src/components'
import { useQuery } from '@apollo/client'
import { GET_USER_PROFILE_INFO } from 'src/utils/queries'
import { CareerSwipeContext } from 'src/utils/providers/useCareerSwipe'
import { Flex } from '@chakra-ui/react'
import ThankYouModal from 'src/components/BracketGame/ThankYou'
import CareerSwipeLogo from 'src/components/BracketGame/CareerSwipeLogo'
import Results from 'src/components/BracketGame/Results'
import Checkpoint from 'src/components/Checkpoint'
import CheckpointOne from 'src/components/BracketGame/CheckpointOne'
import CheckpointTwo from 'src/components/BracketGame/CheckpointTwo'

export default function CareerSwipe() {
  const { careerSwipeStep, error } = useContext(CareerSwipeContext)
  const { data } = useQuery(GET_USER_PROFILE_INFO, {
    onError: (error) => {
      console.error(error)
    },
  })

  const displayRound = () => {
    switch (careerSwipeStep) {
      case 1:
        return <Instructions />
      case 2:
        return <BracketModal />
      case 3:
        return <Results />
      case 4:
        return (
          <Checkpoint error={error}>
            <CheckpointOne />
          </Checkpoint>
        )
      case 5:
        return (
          <Checkpoint error={error}>
            <CheckpointTwo />
          </Checkpoint>
        )
      case 6:
        return <ThankYouModal />
      default:
        return <Instructions />
    }
  }
  return (
    <>
      {(careerSwipeStep < 4 || careerSwipeStep === 6) && (
        <Header firstName={data?.me?.firstName} lastName={data?.me?.lastName} />
      )}
      <Flex direction={'column'} width={'100vw'} minH={'80vh'} align={'center'} justify={'center'}>
        {careerSwipeStep < 4 && <CareerSwipeLogo />}
        {displayRound()}
      </Flex>
    </>
  )
}
