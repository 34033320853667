import React, { useState } from 'react'
import { SurveyQuestion } from '../hooks/useSurvey'
interface SurveyContextProps {
  currentSurveySection: any
  setCurrentSurveySection: (section: any) => void
  currentQuestion: any
  setCurrentQuestion: (question: any) => void
  progress: any
  setProgress: (progress: any) => void
  responseValue: any
  setResponseValue: (response: any) => void
  handleIncomingResponse: (response: any) => void
  responseValueArray: any
  setResponseValueArray: (responseArray: any) => void
}

export const SurveyContext = React.createContext<SurveyContextProps>({
  currentSurveySection: null,
  setCurrentSurveySection: (step: any) => {},
  currentQuestion: null,
  setCurrentQuestion: (question: any) => {},
  progress: null,
  setProgress: (step: any) => {},
  responseValue: null,
  setResponseValue: (response: any) => {},
  responseValueArray: null,
  setResponseValueArray: (response: any) => {},
  handleIncomingResponse: (step: any) => {},
})

export function SurveyProvider({ children }: { children: any }) {
  const [currentQuestion, setCurrentQuestion] = useState<SurveyQuestion | null>(null)
  const [currentSurveySection, setCurrentSurveySection] = useState<any>()
  const [progress, setProgress] = useState(0)
  const [responseValue, setResponseValue] = useState<string>('')
  const [responseValueArray, setResponseValueArray] = useState<string[]>([])
  const MULTIPLE_OPTION_TYPE = 'MULTIPLE_OPTION'

  const jsonParse = (data: string) => {
    try {
      return JSON.parse(data)
    } catch {
      console.error('Error parsing JSON', data)
      throw Error('Error parsing JSON')
    }
  }
  const handleIncomingResponse = ({
    question,
    questionResponse,
  }: {
    question: SurveyQuestion
    questionResponse: string
  }) => {
    if (!questionResponse) {
      setResponseValue('')
      setResponseValueArray([])
      return
    }
    const isMultipleOption = question.questionType === MULTIPLE_OPTION_TYPE
    const parsed = jsonParse(questionResponse)
    isMultipleOption ? setResponseValueArray(parsed) : setResponseValue(parsed)
  }

  return (
    <SurveyContext.Provider
      value={{
        currentQuestion,
        setCurrentQuestion,
        currentSurveySection,
        setCurrentSurveySection,
        progress,
        setProgress,
        responseValue,
        setResponseValue,
        responseValueArray,
        handleIncomingResponse,
        setResponseValueArray,
      }}
    >
      {children}
    </SurveyContext.Provider>
  )
}
