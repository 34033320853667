import React, { Dispatch, SetStateAction, useState, useContext } from 'react'
import {
  Modal,
  ModalContent,
  ModalOverlay,
  ModalHeader,
  ModalBody,
  Image,
  Divider,
  Box,
  Text,
  Container,
  IconButton,
  Flex,
} from '@chakra-ui/react'
import { displaySessionPhoto } from 'src/utils/globalFunctions'
import { useQuery, useMutation } from '@apollo/client'
import { GET_SESSIONS, IS_USER_NOTIFIED } from 'src/utils/queries'
import { ADD_SESSIONS_NOTIFICATION } from 'src/utils/mutations'
import { CourseProps, SessionProps } from 'src/utils/types'
import Loading from '../Loading'
import SessionTime from './SessionTime'
import { AuthContext } from 'src/utils/auth'
import { MdOutlineAddAlert } from 'react-icons/md'
import { Tooltip } from '@chakra-ui/react'
import { FaCheck } from 'react-icons/fa6'

interface RSVPedModalProps {
  setIsOpen: Dispatch<SetStateAction<boolean>>
  isOpen: boolean
  course: CourseProps
  warning: string
  setWarning: Dispatch<SetStateAction<string>>
}

export default function RSVPModal({
  setIsOpen,
  isOpen,
  course,
  warning,
  setWarning,
}: RSVPedModalProps) {
  const { user } = useContext(AuthContext)
  const [futureSessions, setFutureSessions] = useState<SessionProps[]>()
  const [success, setSuccess] = useState(false)
  const toggleCloseModal = () => {
    setIsOpen(false)
  }

  const { loading, refetch } = useQuery(GET_SESSIONS, {
    variables: { courseId: course?.id, email: user?.email },
    fetchPolicy: 'network-only',
    onCompleted: (data: any) => {
      setFutureSessions(data.sessionData.sessions)
      if (data.sessionData.sessions.length === 0) {
        setWarning('No future sessions available. Please check back later.')
      }
    },
    onError: (error: any) => {
      console.error(error)
    },
  })

  const { loading: isUserNotifiedLoading } = useQuery(IS_USER_NOTIFIED, {
    variables: { courseId: course?.courseRecordId, email: user?.email },
    fetchPolicy: 'network-only',
    onCompleted: (data: any) => {
      if (data?.isUserNotified.webInquiries.length > 0) {
        setSuccess(true)
      } else {
        setSuccess(false)
      }
    },
    onError: (error: any) => {
      console.error(error)
    },
  })

  const [addSessionNotification] = useMutation(ADD_SESSIONS_NOTIFICATION, {
    onCompleted: (data) => {
      setSuccess(true)
    },
    onError: (error) => {
      setSuccess(false)
    },
  })

  const submitNotificationRequest = () => {
    addSessionNotification({
      variables: {
        courseId: course.courseRecordId,
        userId: user.id,
      },
    })
  }
  return (
    <Modal isOpen={isOpen} onClose={toggleCloseModal} isCentered size={'lg'}>
      {' '}
      <ModalOverlay />
      <ModalContent
        pt={'20px'}
        px={'50px'}
        pb={'50px'}
        backgroundColor={'white'}
        w={'1000px'}
        borderRadius={'20px'}
      >
        <Flex justifyContent={'end'}>
          <Tooltip label="Notify me of session times" aria-label="A tooltip">
            <IconButton
              color={'black'}
              onClick={submitNotificationRequest}
              variant={'outline'}
              aria-label="notify me of session times"
              icon={
                isUserNotifiedLoading ? (
                  <Loading />
                ) : success ? (
                  <FaCheck color="green" />
                ) : (
                  <MdOutlineAddAlert size={24} />
                )
              }
            />
          </Tooltip>
        </Flex>
        <Image pt={'30px'} m={'auto'} src={displaySessionPhoto(course?.name)} />
        <ModalHeader m={'auto'} color={'black'}>
          {course?.name}
        </ModalHeader>
        <Divider
          height={'3px'}
          w={'90px'}
          m={'auto'}
          mb={'16px'}
          borderRadius={'2px'}
          opacity={100}
          orientation="horizontal"
          backgroundColor={'brand.darkBlue'}
        />

        <ModalBody px={0} textAlign={'center'}>
          <Box>
            <Text textColor="brand.lightGray" fontFamily={'Inter'} fontSize={14}>
              {course?.shortDescription}
            </Text>
          </Box>

          <Divider
            m={'auto'}
            my={'15px'}
            orientation="horizontal"
            backgroundColor={'#DFE4EA'}
            height={'1px'}
          />

          <Container
            overflowY={'scroll'}
            maxH={'250px'}
            sx={{
              '&::-webkit-scrollbar': {
                width: '8px',
                borderRadius: '4px',
                backgroundColor: `rgba(0, 0, 0, 0.05)`,
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: `rgba(0, 0, 0, 0.05)`,
              },
            }}
          >
            {loading ? (
              <Loading />
            ) : (
              futureSessions?.map((futureSession, i) => (
                <SessionTime
                  keyInt={i}
                  key={i}
                  refetchSessions={refetch}
                  session={futureSession}
                  toggleCloseModal={toggleCloseModal}
                />
              ))
            )}
          </Container>
          <Text color={'black'} fontFamily={'Inter'} fontWeight={'bold'} fontSize={14}>
            {warning}
          </Text>
        </ModalBody>
      </ModalContent>{' '}
    </Modal>
  )
}
