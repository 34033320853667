import React, { useContext, useState } from 'react'
import { useQuery, useMutation } from '@apollo/client'
import { Button, Center } from '@chakra-ui/react'
import {
  Flex,
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  useDisclosure,
} from '@chakra-ui/react'
import { GET_SURVEYS, GET_EXPORTS } from 'src/utils/queries'
import { EXPORT_RESPONSES, CALCULATE_SURVEY_RESULTS } from 'src/utils/mutations'
import { Loading, TemplateSelectModal } from 'src/components'
import ExportStatusModal from 'src/components/ExportStatusModal'
import CalculateSurveyModal from 'src/components/CalculateSurveyModal'
import Success from 'src/components/Alerts/Success'
import Error from 'src/components/Alerts/Error'
import { MatchScoreContext } from 'src/utils/providers/useMatchScore'

export interface Survey {
  surveyId: string
  name: string
  selected: boolean
  progress: string
  index: number
  newResponsesCount: number
}

export interface ExportLog {
  id: string
  surveyId: string
  outputFileName: string
  spreadsheetLink: string
  status: string
  templateName: string
  datetimeCreated: string
}

export interface ExportInput {
  fileName: string
  templateName?: string
  responseId?: string
}
export interface ExportProgress {
  surveyId: string
  progressId: string
}

export default function Surveys() {
  const {
    isOpen: isOpenTemplateSelectionModal,
    onOpen: onTemplateSelectOpen,
    onClose: onTemplateSelectClose,
  } = useDisclosure()

  const {
    isOpen: isOpenExportStatusModal,
    onOpen: onExportStatusModalOpen,
    onClose: onExportStatusModalClose,
  } = useDisclosure()

  const {
    isOpen: isOpenCalculateSurveyModal,
    onOpen: onCalculateSurveyModalOpen,
    onClose: onCalculateSurveyModalClose,
  } = useDisclosure()

  const [surveys, setSurveys] = useState<Survey[]>([])
  const [selectedSurvey, setSelectedSurvey] = useState<Survey>()
  const [exports, setExports] = useState<ExportLog[]>()
  const [error, setError] = useState<string | null>(null)

  const { success, setSuccess } = useContext(MatchScoreContext)

  const { loading } = useQuery(GET_SURVEYS, {
    onCompleted: (data) => {
      setSurveys(data.qualtricsData.surveys)
    },
    onError: (error) => {
      setError(error.message)
    },
  })

  const getExports = useQuery(GET_EXPORTS, {
    onCompleted: (data) => {
      setExports(data.responseExports)
    },
    onError: (error) => {
      setError(error.message)
    },
  })

  const [requestExport] = useMutation(EXPORT_RESPONSES, {
    onCompleted: (data) => {
      setSuccess(true)
    },
    onError: (error) => {
      setError(error.message)
    },
    refetchQueries: [{ query: GET_SURVEYS }, { query: GET_EXPORTS }],
  })

  const [calculateSurvey] = useMutation(CALCULATE_SURVEY_RESULTS, {
    onCompleted: (data) => {},
    onError: (error) => {
      setError(error.message)
    },
  })

  const startCalculation = (sheetName: string | undefined) => {
    if (sheetName) {
      const exportData = {
        surveyId: selectedSurvey?.surveyId,
        exportName: sheetName,
        outputFileName: sheetName,
      }
      calculateSurvey({
        variables: {
          exportData,
        },
      })

      onCalculateSurveyModalClose()
    } else {
      console.log('Please select a sheet to calculate survey results')
    }
  }

  if (loading) {
    return (
      <Center mt={100}>
        <Loading />
      </Center>
    )
  }

  const startExport = (data: ExportInput) => {
    if (data['templateName']) {
      const exportData = {
        surveyId: selectedSurvey?.surveyId,
        templateName: data['templateName'],
        exportName: selectedSurvey?.name,
        outputFileName: data['fileName'],
        responseId: data['responseId'],
        isDw: false,
      }

      requestExport({
        variables: {
          exportData,
        },
      })

      onTemplateSelectClose()
    } else {
      console.log('Please select a template to send output data to')
    }
  }

  const openTemplatesModal = (survey: Survey, selectedModal: string) => {
    setSelectedSurvey(survey)
    if (selectedModal === 'Export response') {
      onTemplateSelectOpen()
    } else if (selectedModal === 'Calculate Survey') {
      onCalculateSurveyModalOpen()
    } else {
      onExportStatusModalOpen()
    }
  }

  const renderSurveys = () => {
    return (
      <TableContainer>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Instance Name</Th>
              <Th>New Responses Count</Th>
              <Th>Export Responses</Th>
              <Th>Calculate Results</Th>
              <Th>Export statuses</Th>
            </Tr>
          </Thead>
          <Tbody>
            {surveys?.map((survey: any, index: number) => {
              return (
                <Tr key={index}>
                  <Td>{survey.name}</Td>
                  <Td>{survey.newResponsesCount}</Td>
                  <Td>
                    <Button
                      colorScheme="blue"
                      onClick={() => openTemplatesModal(survey, 'Export response')}
                    >
                      Export Responses
                    </Button>
                  </Td>
                  <Td>
                    <Button
                      onClick={() => openTemplatesModal(survey, 'Calculate Survey')}
                      colorScheme={'blue'}
                    >
                      Calculate Results
                    </Button>
                  </Td>

                  <Td>
                    <Button
                      colorScheme="blue"
                      onClick={() => openTemplatesModal(survey, 'template')}
                    >
                      View statuses
                    </Button>
                  </Td>
                </Tr>
              )
            })}
          </Tbody>
        </Table>
      </TableContainer>
    )
  }

  return (
    <>
      <Center mt={100}>
        <Flex direction="column">
          <Heading mb={20}>Export Survey Responses</Heading>
          {success && <Success />}
          {error && <Error errorMessage={error} setError={setError} />}
          {renderSurveys()}
        </Flex>
      </Center>
      <TemplateSelectModal
        isOpen={isOpenTemplateSelectionModal}
        onClose={onTemplateSelectClose}
        startExport={startExport}
      />
      <ExportStatusModal
        isOpen={isOpenExportStatusModal}
        onClose={onExportStatusModalClose}
        selectedSurvey={selectedSurvey}
      />
      <CalculateSurveyModal
        isOpen={isOpenCalculateSurveyModal}
        onClose={onCalculateSurveyModalClose}
        selectedSurvey={selectedSurvey}
        exports={exports}
        startCalculation={startCalculation}
      />
    </>
  )
}
