import { useMutation } from '@apollo/client'
import {
  Box,
  Button,
  Center,
  Divider,
  FormControl,
  Image,
  Input,
  Spinner,
  Text,
} from '@chakra-ui/react'
import React, { useState } from 'react'
import basta_logo_white from 'src/assets/images/basta_logo_white.png'
import { FORGOT_PASSWORD } from 'src/utils/mutations'

export const SendResetPasswordMail = () => {
  function isValidEmail(email: string) {
    return /\S+@\S+\.\S+/.test(email)
  }
  const [successMessage, setSuccessMessage] = useState('')

  const [sendResetEmail, { loading }] = useMutation(FORGOT_PASSWORD, {
    onCompleted: () => {
      setSuccessMessage(
        'A token has been sent to your email. Check there for a link to reset your password',
      )
    },
    onError: (error) => {
      setRequestError('Could not find user with this email.')
      console.error(error.networkError)
    },
  })
  const [email, setEmail] = useState('')
  const [error, setError] = useState<string | null>(null)
  const [requestError, setRequestError] = useState<string | null>(null)

  const handleSendResetEmail = (e: any) => {
    e.preventDefault()

    if (!isValidEmail(email)) {
      setError('Please enter valid email')
    } else {
      setError(null)
      sendResetEmail({
        variables: {
          email,
        },
      })
    }
  }

  return (
    <>
      <Box className="border-b-1 border-indigo-600">
        <Image mt={25} ml={5} w="135px" src={basta_logo_white} alt={'Basta logo'} />
      </Box>
      <Center>
        <Box margin={3} mt={150} w={'md'}>
          <Center mb={3} textAlign={'center'}>
            <Text fontFamily={'Inter'} fontWeight={'bold'} fontSize={'4xl'}>
              Request to reset your password
            </Text>
          </Center>
          <Divider my={8} orientation="horizontal" />
          <form onSubmit={handleSendResetEmail}>
            <FormControl>
              <Input
                mb={3}
                color={'#000000'}
                _placeholder={{ opacity: 1, color: 'brand.lightBlue' }}
                bg={'white'}
                value={email}
                onChange={(e) => {
                  setRequestError(null)
                  setEmail(e.target.value)
                }}
                placeholder="Email"
              />

              {error && <Text color="red">{error}</Text>}
            </FormControl>

            {requestError && (
              <Box>
                <Text color="red">{requestError}</Text>
              </Box>
            )}
            {successMessage && (
              <Box>
                <Text color="brand.green" fontWeight="bold">
                  {successMessage}
                </Text>
              </Box>
            )}
            <Button
              w={'100%'}
              my={8}
              backgroundColor={'#45A1DC'}
              color={'white'}
              type="submit"
              colorScheme="blue"
              disabled={loading}
            >
              {loading ? <Spinner size="md" /> : 'Send reset password email'}
            </Button>
          </form>
        </Box>
      </Center>
    </>
  )
}
