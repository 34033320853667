import { useQuery } from '@apollo/client'
import { Box, Text, VStack, Center } from '@chakra-ui/react'
import React, { useContext, useState } from 'react'
import { Header } from 'src/components'
import { WelcomeContext } from 'src/utils/providers/useWelcome'
import { GET_USER_PROFILE_INFO, GET_COURSES } from 'src/utils/queries'
import sessionsImg from 'src/assets/images/sessions.png'
import { RSVConfirmationBanner } from 'src/pages/Dashboard'
import MySessions from 'src/components/Sessions/MySessions'
import AllSessions from 'src/components/Sessions/AllSessions'
export interface CourseProps {
  id: string
  name: string
  shortDescription: string
  courseRecordId: string
}
export default function Sessions() {
  const { hasRSVPed, setRSVPStatus } = useContext(WelcomeContext)

  const { data } = useQuery(GET_USER_PROFILE_INFO, {
    onError: (error) => {
      console.error(error)
    },
  })

  const onHideBanner = () => {
    setShowBanner(false)
    setRSVPStatus(false)
  }

  const [, setShowBanner] = useState(false)

  return (
    <Box height={'100dvh'}>
      <VStack h="full">
        <Box w="full">
          <Box px={'3px'}>
            <Header firstName={data?.me?.firstName} lastName={data?.me?.lastName} />
          </Box>
          <Box
            backgroundPosition={'center'}
            backgroundSize={'cover'}
            height={'278px'}
            bgImage={`${sessionsImg}`}
            position="relative"
          >
            <Center
              position="absolute"
              top="0"
              left="0"
              width="100%"
              height={'278px'}
              bg="brand.navy"
              opacity={'90%'}
            >
              <VStack flexGrow={1} spacing={4}>
                <Text
                  textTransform={'none'}
                  fontFamily={'sans-serif'}
                  color="white"
                  fontSize={'3xl'}
                  fontWeight="700"
                >
                  Discovery Sessions
                </Text>
                <Center textAlign={'center'}>
                  <Text fontSize={'lg'}>
                    Explore your recommended path and RSVP to upcoming sessions!
                  </Text>
                </Center>
              </VStack>
            </Center>
          </Box>
        </Box>
        {hasRSVPed ? <RSVConfirmationBanner onClose={onHideBanner} /> : null}

        <Box w="full" flexGrow={1} bg="white">
          <MySessions />
          <AllSessions path={data?.me?.path} program={data?.me?.program?.programType} />
        </Box>
      </VStack>
    </Box>
  )
}
