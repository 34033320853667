import React, { ReactNode, useContext } from 'react'
import { useQuery } from '@apollo/client'
import { Navigate } from 'react-router-dom'
import { AuthContext } from 'src/utils/auth'
import { GET_USER_PROFILE_INFO } from 'src/utils/queries'

interface AdminProps {
  children: ReactNode
}

// Does not allow non staff users to see staff pages

export const AdminRoute = ({ children }: AdminProps) => {
  const { user, token } = useContext(AuthContext)
  const { loading: isProfileLoading, data } = useQuery(GET_USER_PROFILE_INFO, {
    onError: (error) => {
      console.error(error)
    },
  })
  if (user?.isStaff) return <>{children}</>

  if (token && !data?.me?.hasBeenWelcomed) return <Navigate to="/welcome" />
  if (token) return <Navigate to={`/view-report/${user?.responseId}`} />

  return <Navigate to="/log-in" />
}
