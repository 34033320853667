import React, { ChangeEvent } from 'react'
import { Input, FormControl, FormLabel } from '@chakra-ui/react'

type InputProps = {
  label?: string
  value: string
  placeholder?: string
  height?: string
  width?: string
  type?: string
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
}

const TextInput: React.FC<InputProps> = ({
  label,
  value,
  placeholder,
  height,
  width,
  type = 'text',
  onChange,
}) => {
  return (
    <FormControl width={width ? width : '100%'}>
      {label && <FormLabel>{label}</FormLabel>}
      <Input
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        height={height ? height : '40px'}
        width={width ? width : '100%'}
        fontSize="12px"
        fontFamily="Inter"
        fontStyle="normal"
        type={type}
      />
    </FormControl>
  )
}

export default TextInput
