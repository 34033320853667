import { useMutation } from '@apollo/client'
import { Box } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { SeekrSurvey } from 'src/pages/Seekr/SeekrSurvey'
import { RETRIEVE_SURVEY_POSITION } from 'src/utils/mutations'
import Loading from '../../components/Loading'
import { useGetEmailFromParams } from '../../components/Survey/hooks'

export const CheckUserCurrentSurveyStatus = () => {
  const [emailError, setEmailError] = useState('')
  const email = useGetEmailFromParams()

  const [result, setResult] = useState<{
    retrieveCurrentSurveyPosition: {
      currentQuestion: unknown
      isStaff: boolean
      surveyId: string
    }
  }>()

  const [retrieveCurrentPosition] = useMutation(RETRIEVE_SURVEY_POSITION, {
    variables: {
      email,
    },
    onCompleted: (data: any) => {
      setResult(data)
    },
    onError: (error) => {
      console.error('retrieveCurrentPosition', error)
      setEmailError(error.message)
    },
  })

  //! this very much looks like a query
  useEffect(() => {
    retrieveCurrentPosition()
  }, [retrieveCurrentPosition])

  if (!email || emailError) {
    return <Navigate to="/survey/begin" />
  }

  if (!result)
    return (
      <Box as="main" w="100vw" h="100vh" display="flex" justifyContent="center" alignItems="center">
        <Loading />
      </Box>
    )

  return (
    <Routes>
      <Route
        index
        element={
          <SeekrSurvey
            isAdmin={result.retrieveCurrentSurveyPosition.isStaff}
            surveyId={result.retrieveCurrentSurveyPosition.surveyId}
          />
        }
      />
    </Routes>
  )
}
