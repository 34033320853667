import React, { ChangeEvent } from 'react'
import { Select, FormControl, FormLabel } from '@chakra-ui/react'

type OptionType = {
  display: string
  value: string
}

type DropdownProps = {
  label?: string
  options: OptionType[]
  value: string
  placeholder?: string
  height?: string
  width?: string
  onChange: (event: ChangeEvent<HTMLSelectElement>) => void
}

const Dropdown: React.FC<DropdownProps> = ({
  label,
  options,
  value,
  placeholder,
  height,
  width,
  onChange,
}) => {
  return (
    <FormControl width={width ? width : '100%'}>
      {label && <FormLabel>{label}</FormLabel>}
      <Select
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        height={height ? height : '40px'}
        fontSize="12px"
        fontFamily="Inter"
        fontStyle="normal"
      >
        {options.map((option) => (
          <option key={option.value} value={option.value} style={{ fontSize: '12px' }}>
            {option.display}
          </option>
        ))}
      </Select>
    </FormControl>
  )
}

export default Dropdown
