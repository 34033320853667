/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { useMutation } from '@apollo/client'
import { Box, Button, Flex, HStack, Text, VStack, Link, Image } from '@chakra-ui/react'
import React, { useEffect, useMemo, useState, FC, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { Loading } from 'src/components'
import { useGetEmailFromParams } from 'src/components/Survey/hooks'
import Dropdown from 'src/components/Survey/ui/Dropdown'
import Autocomplete from 'src/components/Survey/ui/Autocomplete'
import { SurveyListSingleOption } from 'src/components/Survey/ui/SurveyListOptionInput'
import TextInput from 'src/components/Survey/ui/TextInput'
import { SurveyListMultipleOption } from '../../components/Survey/ui/SurveyListOptionInput'
import { SurveyQuestion, SurveyQuestionOption, useGetSurvey } from '../../utils/hooks/useSurvey'
import {
  RETRIEVE_PREVIOUS_QUESTION,
  RETRIEVE_SURVEY_POSITION,
  SUBMIT_RESPONSE_AND_NEXT_QUESTION,
} from '../../utils/mutations'
import basta_logo_white from 'src/assets/images/basta_logo_white.png'
import { SurveyContext } from 'src/utils/providers/useSurvey'

export const SeekrSurvey: FC<{ isAdmin: boolean; surveyId: string }> = ({ isAdmin, surveyId }) => {
  //TODO: will use this to pass it to the retrieve current survey status so that we can check what the current position of the survey is.
  const {
    currentQuestion,
    setCurrentQuestion,
    responseValue,
    setResponseValue,
    responseValueArray,
    setResponseValueArray,
    currentSurveySection,
    setCurrentSurveySection,
    handleIncomingResponse,
    progress,
    setProgress,
  } = useContext(SurveyContext)
  const email = useGetEmailFromParams()
  const [firstQuestion, setFirstQuestion] = useState<SurveyQuestion | null>(null)
  const [globalFirst, setGlobalFirst] = useState<SurveyQuestion | null>(null)
  const [globalLast, setGlobalLast] = useState<SurveyQuestion | null>(null)
  const [sectionId, setSectionId] = useState<string | null>(null)
  const [validationError, setValidationError] = useState('')
  const [singleAnswer, setSingleAnswer] = useState(false)

  const preProcessedResponseValue = useMemo(() => {
    const isMultiple = currentQuestion?.questionType === 'MULTIPLE_OPTION'
    if (isMultiple) {
      return responseValueArray
    }
    return responseValue
  }, [currentQuestion?.questionType, responseValue, responseValueArray])

  const { loading: loadingMainSurvey, surveySection: mainSurvey } = useGetSurvey(surveyId)
  const { loading, surveySection } = useGetSurvey(sectionId ?? undefined)
  const navigate = useNavigate()

  //EFFECTS
  useEffect(() => {
    if (!isAdmin && mainSurvey && !sectionId) {
      const found = mainSurvey?.seekrSurveyRetrieve?.sections?.find((s) => s.order === 1)
      found && setSectionId(found.id)
      return
    }
    if (mainSurvey && sectionId && surveySection) {
      setCurrentSurveySection(surveySection)
    }
  }, [currentQuestion, isAdmin, mainSurvey, sectionId, surveySection])

  useEffect(() => {
    if (currentSurveySection && currentSurveySection?.seekrSurveyRetrieve?.questions) {
      const firstQuestion =
        currentSurveySection?.seekrSurveyRetrieve.questions.find(
          (question: any) => question.isFirst,
        ) ?? null
      setFirstQuestion(firstQuestion)
    }
  }, [currentSurveySection])

  useEffect(() => {
    if (progress > 0) {
      onContinue()
    } else {
      setCurrentQuestion(firstQuestion)
    }
  }, [firstQuestion])

  useEffect(() => {
    if (mainSurvey) {
      setGlobalFirst(mainSurvey?.seekrSurveyRetrieve?.firstQuestion)
    }
  }, [mainSurvey])
  useEffect(() => {
    if (mainSurvey) {
      setGlobalLast(mainSurvey?.seekrSurveyRetrieve?.lastQuestion)
    }
  }, [mainSurvey])

  /**
   *  Centralize where the value changes happen
   */
  const handleResponseValue = (value: string | string[]) => {
    if (Array.isArray(value)) {
      setResponseValueArray(value)
      return
    }
    setResponseValue(value)
  }

  const handleResponseValueSingle = (value: string) => {
    setResponseValue(value)
    setSingleAnswer(true)
  }

  const getComponent = ({
    key,
    questionDisplay,
    questionType,
    options = [],
  }: {
    questionType: string
    questionDisplay: string
    key: string
    options?: SurveyQuestionOption[]
  }) => {
    switch (`${questionType}__${questionDisplay}`) {
      case 'SINGLE_OPTION__LIST_VERTICAL':
        return (
          <SurveyListSingleOption
            name={key}
            layout="column"
            options={options.map((x) => {
              return {
                label: x.display,
                value: x.value,
              }
            })}
            value={responseValue}
            onChange={handleResponseValueSingle}
          />
        )

      case 'SINGLE_OPTION__DROPDOWN':
        return (
          <Dropdown
            placeholder="Select option"
            onChange={(e) => handleResponseValue(e.target.value)}
            value={responseValue}
            options={options.map((o) => ({
              display: o.cleanDisplay,
              value: o.value,
            }))}
          />
        )

      case 'SINGLE_OPTION__GRID_LAYOUT':
        return (
          <SurveyListSingleOption
            name={key}
            layout="grid"
            options={options.map((x) => {
              return {
                label: x.display,
                value: x.value,
              }
            })}
            onChange={handleResponseValueSingle}
            value={responseValue}
          />
        )
      case 'SINGLE_OPTION__LIST_HORIZONTAL':
        return (
          <SurveyListSingleOption
            name={key}
            layout="row"
            options={options.map((x) => {
              return {
                label: x.display,
                value: x.value,
              }
            })}
            onChange={handleResponseValueSingle}
            value={responseValue}
          />
        )
      case 'MULTIPLE_OPTION__DROPDOWN':
        return null
      case 'MULTIPLE_OPTION__GRID_LAYOUT':
        return (
          <SurveyListMultipleOption
            name={key}
            layout="grid"
            options={options.map((x) => {
              return {
                label: x.display,
                value: x.value,
              }
            })}
            onChange={handleResponseValue}
            value={responseValueArray}
          />
        )
      case 'MULTIPLE_OPTION__LIST_HORIZONTAL':
        return (
          <SurveyListMultipleOption
            name={key}
            layout="row"
            options={options.map((x) => {
              return {
                label: x.display,
                value: x.value,
              }
            })}
            onChange={handleResponseValue}
            value={responseValueArray}
          />
        )
      case 'MULTIPLE_OPTION__LIST_VERTICAL':
        return (
          <SurveyListMultipleOption
            name={key}
            layout="column"
            options={options.map((x) => {
              return {
                label: x.display,
                value: x.value,
              }
            })}
            onChange={handleResponseValue}
            value={responseValueArray}
          />
        )

      case 'TEXT__OPEN_TEXT':
        return (
          <TextInput
            placeholder="Enter text here"
            onChange={(e) => handleResponseValue(e.target.value)}
            value={responseValue}
          />
        )

      case 'TEXT__AUTOCOMPLETE':
        return <Autocomplete value={responseValue} handleResponseValue={handleResponseValue} />

      default:
        return null
    }
  }

  const [submitSurveyResponseAndNext] = useMutation(SUBMIT_RESPONSE_AND_NEXT_QUESTION, {
    variables: {
      email,
      questionId: currentQuestion?.id,
      responseValue: JSON.stringify(preProcessedResponseValue),
    },
    onCompleted: (data) => {
      if (data.submitSurveyResponseAndNext.errors) {
        setValidationError(data.submitSurveyResponseAndNext.errors.join(', '))
        return
      }
      if (data.submitSurveyResponseAndNext.nextQuestion) {
        handleIncomingResponse({
          questionResponse: data.submitSurveyResponseAndNext.nextResponse,
          question: data.submitSurveyResponseAndNext.nextQuestion,
        })
        setProgress(data.submitSurveyResponseAndNext.progress)
        setCurrentQuestion(data.submitSurveyResponseAndNext.nextQuestion)
      }
    },
    onError: (error) => {
      console.error('submitSurveyResponseAndNext', error)
    },
  })

  const [retrievePreviousQuestion] = useMutation(RETRIEVE_PREVIOUS_QUESTION, {
    variables: {
      email,
      questionId: currentQuestion?.id,
      responseValue: responseValue,
    },
    onCompleted: (data: any) => {
      setCurrentQuestion(data.retrievePreviousQuestion.previousQuestion)
      setProgress(data.retrievePreviousQuestion.progress)
      handleIncomingResponse({
        questionResponse: data.retrievePreviousQuestion.previousResponse,
        question: data.retrievePreviousQuestion.previousQuestion,
      })
    },
    onError: (error) => {
      console.error('retrievePreviousQuestion', error)
    },
  })

  const [retrieveCurrentPosition] = useMutation(RETRIEVE_SURVEY_POSITION, {
    variables: {
      email,
    },
    onCompleted: (data: any) => {
      if (
        !data.retrieveCurrentSurveyPosition?.currentSection ||
        !data.retrieveCurrentSurveyPosition?.currentQuestion
      ) {
        alert("You haven't started any surveys yet please select a survey to start")
        return
      }
      setCurrentSurveySection(data.retrieveCurrentSurveyPosition.currentSection)
      setCurrentQuestion(data.retrieveCurrentSurveyPosition.currentQuestion)
      setProgress(data.retrieveCurrentSurveyPosition.progress)
      handleIncomingResponse({
        questionResponse: data.retrieveCurrentSurveyPosition.currentResponse,
        question: data.retrieveCurrentSurveyPosition.currentQuestion,
      })
    },
    onError: (error) => {
      console.error('retrieveCurrentPosition', error)
    },
  })

  const onSubmit = async () => {
    setValidationError('')
    submitSurveyResponseAndNext()
  }

  useEffect(() => {
    if (singleAnswer) {
      setValidationError('')
      submitSurveyResponseAndNext({
        variables: {
          email,
          questionId: currentQuestion?.id,
          responseValue: JSON.stringify(responseValue),
        },
      })
    }
    setSingleAnswer(false)
  }, [currentQuestion?.id, email, responseValue, singleAnswer, submitSurveyResponseAndNext])

  const onFinish = async () => {
    submitSurveyResponseAndNext()
    setCurrentQuestion(null)
    setSectionId(null)
    setCurrentSurveySection(null)
    setProgress(0)
    navigate('/survey/complete')
  }
  const clearSelections = () => {
    setCurrentQuestion(null)
    setCurrentSurveySection(null)
    setSectionId(null)
    setResponseValue('')
    setResponseValueArray([])
    setProgress(0)
  }

  const onBack = async () => {
    setValidationError('')
    retrievePreviousQuestion()
  }

  const onContinue = () => {
    retrieveCurrentPosition()
  }

  if (loadingMainSurvey || loading || !mainSurvey) {
    return <Loading />
  }

  const orderedOptions = currentQuestion?.options?.sort((a: any, b: any) => a.order - b.order)

  return (
    <VStack as="main" px={3} alignItems="center">
      <HStack justify="space-between" w="full">
        <Link href="/survey">
          <Box py={4}>
            <Image mt={25} ml={5} w="135px" src={basta_logo_white} />
          </Box>
        </Link>
      </HStack>{' '}
      <Flex direction="column" w="full" maxW="4xl" h="100%" px={10} gap={4}>
        {currentSurveySection && currentQuestion ? (
          <>
            {isAdmin ? (
              <Button
                onClick={() => {
                  clearSelections()
                }}
              >
                Close
              </Button>
            ) : null}
            <Box w="full">
              <HStack w="full" justifyContent="center">
                {Array.from({ length: mainSurvey?.seekrSurveyRetrieve?.sections?.length ?? 0 }).map(
                  (_, i) => {
                    return (
                      <span
                        key={i}
                        style={{
                          backgroundColor: progress && i + 1 == progress * 10 ? 'white' : 'gray',
                          height: '10px',
                          width: '10px',
                        }}
                      ></span>
                    )
                  },
                )}
              </HStack>
            </Box>
            <Text dangerouslySetInnerHTML={{ __html: currentQuestion?.question ?? '' }}></Text>
            {currentQuestion ? (
              <>
                {' '}
                {getComponent({
                  questionType: currentQuestion?.questionType,
                  questionDisplay: currentQuestion?.questionDisplay,
                  key: currentQuestion?.exportKey,
                  options: orderedOptions,
                })}
              </>
            ) : null}

            <HStack w="full" justifyContent="center" h="8">
              {validationError ? (
                <Text color="red.400" fontWeight="bold">
                  {validationError}
                </Text>
              ) : null}
            </HStack>

            <HStack w="full" justifyContent="space-between">
              {!(globalFirst?.id == currentQuestion?.id) ? (
                <Button onClick={onBack}>Back</Button>
              ) : (
                <div></div>
              )}
              {globalLast?.id == currentQuestion?.id ? (
                <Button onClick={onFinish}>Finish</Button>
              ) : (
                <Button onClick={onSubmit}>Next</Button>
              )}
            </HStack>
          </>
        ) : isAdmin ? (
          <VStack>
            <VStack>
              <Text>Select A survey</Text>

              <Dropdown
                placeholder="Select survey"
                onChange={(e) => setSectionId(e.target.value)}
                value={currentSurveySection ?? ''}
                options={
                  mainSurvey?.seekrSurveyRetrieve?.sections?.map((o) => ({
                    display: o.title,
                    value: o.id,
                  })) ?? []
                }
              />
            </VStack>
            <VStack>
              <Text>Or Continue Where you left off from</Text>
              <Button onClick={onContinue}>Continue</Button>
            </VStack>
          </VStack>
        ) : null}
      </Flex>
    </VStack>
  )
}
