import React from 'react'
import { Navigate, Outlet, Route, Routes } from 'react-router-dom'
import { AdminRoute } from 'src/components/ProtectedRoutes/AdminRoute'
import {
  DataWarehouse,
  Explore,
  Exports,
  Home,
  JobParse,
  LogIn,
  SignUp,
  MatchScore,
  NextSteps,
  Report,
  ResetPassword,
  SendResetPasswordMail,
  Surveys,
  Templates,
  Welcome,
} from 'src/pages'
import CareerSwipe from 'src/pages/CareerSwipe'
import { Dashboard } from 'src/pages/Dashboard'
import DebriefCheckpoint from 'src/pages/DebriefCheckpoint'
import { Profile } from 'src/pages/Profile'
import SocialCapital from 'src/pages/Report/SocialCapital'
import Scheduler from 'src/pages/Scheduler'
import { BeginSurvey, CheckUserCurrentSurveyStatus } from 'src/pages/Seekr'
import SurveyComplete from 'src/pages/Seekr/SurveyComplete'
import Sessions from 'src/pages/Sessions'

export const PUBLIC_ROUTES = [
  '/log-in',
  '/sign-up',
  '/match',
  '/job-parse',
  '/forgot-password',
  '/survey',
  '/survey-complete',
]
export const PUBLIC_DYNAMIC_ROUTES = [
  /^\/view-report\//,
  /^\/reset-password\//,
  /^\/forgot-password\//,
  /^\/survey\//,
  /^\/sign-up\//,
]
export const ROUTES = (
  <Routes>
    <Route path="/log-in" element={<LogIn />} />
    <Route path="/sign-up" element={<SignUp />} />
    <Route path="/match" element={<MatchScore />} />
    <Route path="/job-parse" element={<JobParse />} />
    <Route path="/welcome" element={<Welcome />} />
    <Route path="/view-report/:responseId" element={<Report />} />
    <Route path="/view-report/:responseId/next-steps" element={<NextSteps />} />
    <Route path="/view-report/:responseId/social-capital" element={<SocialCapital />} />
    <Route path="/view-report/:responseId/explore" element={<Explore />} />
    <Route path="/forgot-password" element={<SendResetPasswordMail />} />
    <Route path="/reset-password/:email/:token" element={<ResetPassword />} />
    <Route path="/sessions" element={<Sessions />} />
    <Route path="/dashboard" element={<Dashboard />} />
    <Route path="/debrief-checkpoint" element={<DebriefCheckpoint />} />
    <Route path="/scheduler" element={<Scheduler />} />
    <Route path="/profile" element={<Profile />} />
    <Route path="/career-swipe" element={<CareerSwipe />} />
    <Route path="/survey/begin" element={<BeginSurvey />} />
    <Route path="/survey/complete" element={<SurveyComplete />} />
    <Route path="/survey/seekr" element={<CheckUserCurrentSurveyStatus />} />
    <Route path="/survey/*" element={<Navigate to="/survey/begin" />} />
    {/* <Route path="my-test-page" element={<SurveyUIPlayground />} /> */}

    <Route
      path="/"
      element={
        <AdminRoute>
          {' '}
          <Home />
        </AdminRoute>
      }
    >
      <Route path="/surveys" element={<Surveys />} />
      <Route path="/templates" element={<Templates />} />
      <Route path="/exports" element={<Exports />} />
      <Route path="/data-warehouse" element={<DataWarehouse />} />
    </Route>
    <Route
      path="/"
      element={
        <AdminRoute>
          <Outlet />
        </AdminRoute>
      }
    ></Route>
  </Routes>
)
