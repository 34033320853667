import { Button, HStack, Text, Icon, Stack, VStack } from '@chakra-ui/react'
import React, { useContext } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { FaRegClock } from 'react-icons/fa'
import { formatDate, formatTime } from 'src/utils/globalFunctions'
import { SessionProps } from 'src/utils/types'
import { RSVP_FOR_SESSION } from 'src/utils/mutations'
import { WelcomeContext } from 'src/utils/providers/useWelcome'
import { AuthContext } from 'src/utils/auth'
import {
  GET_USER_PROFILE_INFO,
  GET_USER_RSVPS,
  GET_UPCOMING_SESSIONS,
  GET_SESSIONS,
} from 'src/utils/queries'

interface SessionTimeProps {
  toggleCloseModal: () => void
  refetchSessions: () => void
  session: SessionProps
  keyInt: number
}

export default function SessionTime({
  session,
  toggleCloseModal,
  refetchSessions,
  keyInt,
}: SessionTimeProps) {
  const { setRSVPStatus, setError } = useContext(WelcomeContext)
  const { user } = useContext(AuthContext)

  const { data } = useQuery(GET_USER_PROFILE_INFO, {
    onError: (error) => {
      console.error(error)
    },
  })
  const [rsvpForSession] = useMutation(RSVP_FOR_SESSION, {
    onCompleted: (data) => {
      setRSVPStatus(true)
      toggleCloseModal()
      refetchSessions()
    },
    onError: (error) => {
      console.error(error)
      setError(error.message)
    },
    refetchQueries: [
      { query: GET_USER_RSVPS, variables: { email: user?.email } },
      'userRData',
      { query: GET_UPCOMING_SESSIONS, variables: { email: user?.email } },
      'upcomingSessionData',
      { query: GET_SESSIONS, variables: { courseId: session.course.id, email: user?.email } },
      'sessionData',
    ],
  })

  const submitRsvp = (email: string, session_id: string) => {
    rsvpForSession({
      variables: {
        userEmail: email,
        sessionId: session_id,
        isDebrief: true,
      },
    })
  }

  return (
    <Stack key={keyInt} direction={'row'} justifyContent={'space-between'} my={'25px'}>
      <HStack>
        <Icon
          as={FaRegClock}
          borderRadius={'8px'}
          color={'brand.darkBlue'}
          width={'43px'}
          height={'43px'}
          p={'11px'}
        />
        <VStack spacing={0} align={'flex-start'}>
          <Text fontFamily={'Inter'} textColor="black">
            {formatDate(session?.date, 'ordinal')}
          </Text>
          <Text color={'black'} fontFamily={'Inter'} fontWeight={'bold'} fontSize={14}>
            {formatTime(session?.date)}
          </Text>
        </VStack>
      </HStack>
      <Button
        variant="solid"
        _hover={{ bg: '#3764b4' }}
        bg={'brand.blue'}
        color={'white'}
        title={'RSVP'}
        fontWeight={500}
        onClick={() => submitRsvp(data?.me?.email, session?.sessionId)}
      >
        RSVP
      </Button>
    </Stack>
  )
}
