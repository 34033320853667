import * as React from 'react'
import { ChakraProvider } from '@chakra-ui/react'
import theme from './theme'
import Fonts from './theme/foundations/fonts'
import { BrowserRouter as Router } from 'react-router-dom'
import { ApolloProvider } from '@apollo/client'
import { client } from './apolloClient'
import { ROUTES } from 'src/utils/routes'

import '@fontsource/karla'
import '@fontsource/inter'
import './App.css'
import 'src/assets/fonts/Intro.otf'
import Providers from './utils/providers/providers'

export const App = () => (
  <ChakraProvider theme={theme}>
    <Fonts />
    <Router>
      <ApolloProvider client={client}>
        <Providers routes={ROUTES} />
      </ApolloProvider>
    </Router>
  </ChakraProvider>
)
