import {
  Box,
  Button,
  Flex,
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  Spinner,
} from '@chakra-ui/react'
import React, { FC, useContext } from 'react'
import { FaChevronDown } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import { AuthContext, logout } from 'src/utils/auth'
import Logo from './Logo'

export const Header: FC<{ firstName: string; lastName: string }> = ({ firstName, lastName }) => {
  const { updateToken } = useContext(AuthContext)
  const navigate = useNavigate()

  const logUserOut = () => {
    logout()
    updateToken(null)
    navigate('/log-in')
  }
  const goToProfile = () => {
    navigate('/profile')
  }
  const goToSessions = () => {
    navigate('/sessions')
  }

  const fullName = `${firstName} ${lastName}`
  const initials = !firstName && !lastName ? '?' : `${firstName?.[0]}${lastName?.[0]}`

  return (
    <HStack justify="space-between" w="full">
      <Box py={4}>
        <Logo m={0} />
      </Box>
      <Menu>
        <MenuButton as={Button} variant="none" p={0} m={0}>
          <HStack justifyContent={'end'} py="2" w="full">
            <HStack spacing={2} bg="#2a2e39" px="4" py="2" rounded="full" alignItems={'center'}>
              {firstName || lastName ? (
                <>
                  <Flex
                    rounded={'full'}
                    w={'8'}
                    h={'8'}
                    display={{
                      base: 'none',
                      md: 'flex',
                    }}
                    alignItems={'center'}
                    justify="center"
                    bg={'brand.neutral'}
                  >
                    <Text
                      color="brand.text"
                      fontFamily="sans-serif"
                      fontWeight="bold"
                      textTransform="uppercase"
                      fontSize={'md'}
                      textAlign={'center'}
                    >
                      {initials}
                    </Text>
                  </Flex>
                  <Box>
                    <Text
                      color="brand.white"
                      fontFamily="sans-serif"
                      textTransform="capitalize"
                      fontSize={{
                        base: 'xs',
                        md: 'md',
                      }}
                    >
                      {fullName}
                    </Text>
                  </Box>
                  <Flex alignItems={'center'}>
                    <Icon as={FaChevronDown} color={'brand.white'} />
                  </Flex>
                </>
              ) : (
                <Spinner
                  thickness="2px"
                  speed="0.65s"
                  emptyColor="gray.200"
                  color="current"
                  size="md"
                />
              )}
            </HStack>
          </HStack>
        </MenuButton>
        <MenuList>
          <MenuItem onClick={goToProfile}>Profile</MenuItem>
          <MenuItem onClick={goToSessions}>Sessions</MenuItem>
          <MenuItem onClick={logUserOut}>Logout</MenuItem>
        </MenuList>
      </Menu>
    </HStack>
  )
}
