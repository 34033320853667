import { useMutation } from '@apollo/client'
import {
  Box,
  Button,
  Center,
  Divider,
  FormControl,
  Icon,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  Link,
} from '@chakra-ui/react'
import { default as React, useContext, useEffect, useState } from 'react'
import { FaEye, FaEyeSlash } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import basta_logo_white from 'src/assets/images/basta_logo_white.png'
import { AuthContext } from 'src/utils/auth'
import { LOG_IN } from 'src/utils/mutations'

export default function LogIn() {
  const { updateToken, setUser } = useContext(AuthContext)
  const navigate = useNavigate()
  function isValidEmail(email: string) {
    return /\S+@\S+\.\S+/.test(email)
  }
  const [login] = useMutation(LOG_IN, {
    onCompleted: (data) => {
      localStorage.setItem('auth-token', data.tokenAuth.token)
      localStorage.setItem('user', JSON.stringify(data.tokenAuth.user))
      updateToken(data.tokenAuth.token)
      setUser(data.tokenAuth.user)
      if (data.tokenAuth.user.isStaff) {
        navigate('/surveys')
        return
      }
      if (!data.tokenAuth.user.hasBeenWelcomed) {
        navigate('/welcome')
        return
      }
      navigate('/dashboard')
    },
    onError: (error) => {
      setRequestError(error.graphQLErrors?.[0]?.message)
      console.error(error.networkError)
    },
  })
  const [email, setEmail] = useState('')
  const [show, setShow] = React.useState(false)
  const [error, setError] = useState<string | null>(null)
  const [resetEmail, setResetEmail] = useState<string | null>(null)
  const [requestError, setRequestError] = useState<string | null>(null)
  const [password, setPassword] = useState('')
  const handleTogglePasswordVisibility = () => setShow(!show)

  const handleLogin = (e: any) => {
    e.preventDefault()

    if (!isValidEmail(email)) {
      setError('Please enter valid email')
    } else {
      setError(null)
      login({
        variables: {
          email,
          password,
        },
      })
    }
  }

  useEffect(() => {
    const urlString = window.location.search
    const urlParams = new URLSearchParams(urlString)
    if (urlParams.get('email')) {
      const urlEmail = urlParams.get('email')
      if (urlEmail !== null) {
        setResetEmail(urlEmail)
        setEmail(urlEmail)
      } else {
        setError('User not found.')
      }
    }
  }, [])

  return (
    <>
      <Box className="border-b-1 border-indigo-600">
        <Image mt={25} ml={5} w="135px" src={basta_logo_white} alt={'Basta logo'} />
      </Box>
      <Center>
        <Box mt={150} w={'md'}>
          <Center mb={3}>
            <Text
              fontFamily={'Inter'}
              fontWeight={'bold'}
              fontSize={'4xl'}
              data-testid="login-title"
            >
              Sign In
            </Text>
          </Center>
          <Divider my={8} orientation="horizontal" />
          <form onSubmit={handleLogin}>
            <FormControl>
              {resetEmail ? (
                <Input
                  mb={3}
                  isDisabled
                  overflow={'scroll'}
                  color={'#000000'}
                  _placeholder={{ opacity: 1, color: 'brand.lightBlue' }}
                  bg={'white'}
                  value={resetEmail}
                  resize={'horizontal'}
                />
              ) : (
                <Input
                  mb={3}
                  color={'#000000'}
                  _placeholder={{ opacity: 1, color: 'brand.lightBlue' }}
                  bg={'white'}
                  value={email}
                  onChange={(e) => {
                    setRequestError(null)
                    setEmail(e.target.value)
                  }}
                  placeholder="Email"
                  data-testid="email"
                />
              )}

              {error && <Text color="red">{error}</Text>}
            </FormControl>
            <FormControl>
              <InputGroup size="md">
                <Input
                  type={show ? 'text' : 'password'}
                  color={'#000000'}
                  _placeholder={{ opacity: 1, color: 'brand.lightBlue' }}
                  bg={'white'}
                  value={password}
                  placeholder="Password"
                  data-testid="password"
                  onChange={(e) => {
                    setRequestError('')
                    return setPassword(e.target.value)
                  }}
                  mb={3}
                />
                <InputRightElement width="4.5rem">
                  <Button
                    h="1.75rem"
                    color={'#000000'}
                    size="sm"
                    onClick={handleTogglePasswordVisibility}
                  >
                    {show ? <Icon as={FaEyeSlash} /> : <Icon as={FaEye} />}
                  </Button>
                </InputRightElement>
              </InputGroup>
            </FormControl>
            <Box mt={1} textAlign={'right'}>
              <Link href="/forgot-password">
                <Text textColor={'#E2E8F0'} fontWeight={'bold'} as={'u'}>
                  Forgot password?
                </Text>
              </Link>
            </Box>
            {requestError && (
              <Box>
                <Text color="red">{requestError}</Text>
              </Box>
            )}
            <Button
              w={'100%'}
              my={8}
              backgroundColor={'#45A1DC'}
              color={'white'}
              type="submit"
              colorScheme="blue"
              data-testid="login-btn"
            >
              Sign In
            </Button>
          </form>
          {/* <Center>
            <Text mr={1}>Don't have an account? </Text>
            <Link href="sign-up">
            <Text fontWeight={"bold"} as={"u"}>
              Sign Up
            </Text>
            </Link>
          </Center> */}
        </Box>
      </Center>
    </>
  )
}
